import React from 'react'
import { useFrame, useLoader } from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'

export default function Box(props) {
  const colorMap = useLoader(TextureLoader, './blackbox-black-border.jpeg')
  const mesh = React.useRef(null)
  useFrame((state, delta) => (mesh.current.rotation.x += 0.001))
  useFrame((state, delta) => (mesh.current.rotation.y += 0.002))
  useFrame((state, delta) => (mesh.current.rotation.z += 0.0005))
  return (
    <mesh
      {...props}
      ref={mesh}>
      <boxGeometry args={[3, 3, 3]} />
      <meshStandardMaterial map={colorMap} roughness={1} metalness={1} />
    </mesh>
  )
}