import React from "react"
import StakingBlack from "../images/blackbox-token-staking.png"

const buttonLink = {
    stakeBlack: { href: "https://app.blackboxfinancetech.com/staking" },
    buyBlack: { href: "https://quickswap.exchange/#/analytics/pair/0xC77f6b3624f6f1A6157482AE74c94Ba2923ac96C" }
}

export default function MainContent3() {
    return (
        <div className="relative bg-gradient-to-tr from-zinc-600 to-slate-200">
            <section className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-12 lg:py-20">
                <h2 className="text-black text-4xl font-semibold text-right mb-10">Staking</h2>
                <div className="flex ">
                    <div className="flex flex-col md:w-7/12 lg:h-96 lg:w-10/12 border-2 space-y-2 mt-6 rounded-xl p-12 bg-zinc-700 border-zinc-300">
                        <h3 className="text-white font-medium text-3xl">Stake BLACK, claim USDC</h3>
                        <p className="text-white">100% of Blackbox platform fees(USDC) are redistributed to BLACK token stakers.
                            The BLACK token can be acquired by opening Blackbox or directly buying via DEXs.</p>
                        <br></br>
                        <div className="flex flex-col items-center w-auto md:flex-row space-x-2 md:pt-16">
                            <a href={buttonLink.stakeBlack.href} key="StakeBLACK" className=" text-white text-center pt-4 pb-3 px-5 rounded-lg bg-yellow-700 mb-4 w-full md:w-auto md:mb-0 hover:bg-yellow-600 hover:font-medium">Stake BLACK</a>
                            <a href={buttonLink.buyBlack.href} key="BuyBLACK" className="text-black font-medium text-center pt-3 px-8 rounded-lg bg-white w-full md:w-auto hover:bg-zinc-100 hover:font-semibold">
                                Buy BLACK
                                <p className="text-xs ">via Quickswap</p>
                            </a>
                        </div>
                    </div>
                    <div className="relative invisible h-0 w-0  m-0 md:visible md:w-96 md:h-[28rem] lg:w-auto lg:h-[32rem] lg:ml-24 overflow-hidden ">
                        <div className="w-0 ml-0  md:w-[48rem] mr-72 md:-ml-40 lg:-ml-32 -mt-36">
                            <img src={StakingBlack} alt="BLACK coin staking" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}