import Box from "./Box.js"
import { Canvas } from '@react-three/fiber'


export default function BlackBox3D() {
  return (
    <Canvas shadows={true}>
      <ambientLight intensity={0.1} />
      <directionalLight position={[100, 100, 50]} intensity={3} castShadow shadow-mapSize={[1024, 1024]} />
      <Box position={[0, 0, 0]} />
    </Canvas>
  )
}

