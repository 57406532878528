import React from "react"
import BlackboxLogo from "./BlackboxLogo";
import "../styles.css";
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import Logo from "../images/blackbox-logo.svg"
const navigation = [
    { name: "Community", href: "https://discord.gg/7VR3NsvKsg" },
    { name: "Staking", href: "https://app.blackboxfinancetech.com/staking" },
    { name: "Docs", href: "https://docs.blackboxfinancetech.com/" },
    { name: "FAQ", href: "https://docs.blackboxfinancetech.com/resources/faq" },

]
const launchApp = { name: "launchApp", href: "https://app.blackboxfinancetech.com/" };

export default function Navbar() {

    return (
        <Popover className="sticky top-0 z-10 bg-black">
            <div className="relative h-16 px-4 max-w-7xl mx-auto sm:px-6 lg:px-12">
                <nav className="relative flex items-center justify-between h-full" aria-label="Global">
                    <div className="flex items-center justify-between w-full flex-shrink-0 md:w-auto lg:flex-grow-0">
                        <BlackboxLogo />
                        <div className="-mr-2 flex items-center md:hidden">
                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-800 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-800">
                                <span className="sr-only">Open main menu</span>
                                <MenuIcon className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                        </div>
                    </div>
                    <div className="hidden md:flex md:items-center md:content-center md:ml-10 md:h-full" >
                        {navigation.map((item) => (

                            <a key={item.name} href={item.href} className="font-regular h-full w-28 pt-5 text-center hover:bg-gray-700 text-white hover:font-medium">
                                {item.name}
                            </a>

                        ))}
                        <a className="rounded-md bg-sky-700 w-32 h-10 font-regular text-center text-white pt-2 hover:bg-sky-600 hover:font-medium md:ml-3" href={launchApp.href}>
                            Launch App
                        </a>
                    </div>
                </nav>
            </div>

            <Transition
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel
                    focus
                    className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                >
                    <div className="rounded-lg shadow-md bg-slate-800 ring-1 ring-white ring-opacity-5 overflow-hidden">
                        <div className="px-5 pt-4 flex items-center justify-between">
                            <div>
                                <img
                                    className="h-12 w-auto rounded-lg"
                                    src={Logo}
                                    alt=""
                                />
                            </div>
                            <div className="-mr-2">
                                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-800 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-800">
                                    <span className="sr-only">Close main menu</span>
                                    <XIcon className="h-6 w-6" aria-hidden="true" />
                                </Popover.Button>
                            </div>
                        </div>
                        <div className="px-2 pt-4 space-y-2 mb-4">
                            {navigation.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className="block px-3 w-full rounded-md text-base font-medium text-white hover:bg-slate-700"
                                >
                                    {item.name}
                                </a>
                            ))}
                        </div>
                        <a
                            href={launchApp.href}
                            className="block w-full px-5 py-3 text-center font-medium text-white bg-sky-700 hover:bg-sky-600"
                        >
                            Launch App
                        </a>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}