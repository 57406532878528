import React from "react"

export default function ShowActiveRounds() {
    return (
        <div class="h-auto bg-slate-100 w-auto max-h-0">
            <div class="grid grid-cols-8 pl-10 pt-4 pb-4 bg-slate-100">
                <div class="flex col-start-1 justify-center col-end-9">
                    <h2 class="text-xl font-semibold">Blackbox rounds</h2>
                </div>
            </div>
            <div class="grid grid-cols-6 bg-slate-200 h-12 mb-0 border-slate-300 border">
                <div class="col-start-1 col-end-3 text-center py-1">
                    <p class="font-semibold">Left side</p><p class="font-regular text-xs">side ratios</p>
                </div>
                <div class="col-start-3 col-end-5 text-center py-1">
                    <p class="font-semibold">Right side</p>
                    <p class="font-regular text-xs">side ratios</p>
                </div>
                <div class="col-start-5 col-end-7 text-center font-semibold py-3">Volume(USDC)</div>
            </div>
            <div>
                <div class="grid grid-cols-6">
                    <div class="col-start-1 col-end-3 flex flex-col hover:shadow-inner bg-slate-100">
                        <div class="flex">
                            <img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/42.png" alt="Arsenal" />
                            <p class="text-sm ml-3 mt-4">Arsenal</p>
                        </div>
                        <div class="text-center">
                            <p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-3 col-end-5 flex flex-col hover:shadow-inner bg-slate-100">
                        <div class="flex">
                            <img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/46.png" alt="Leicester" />
                            <p class="text-sm ml-3 mt-4">Leicester</p>
                        </div>
                        <div class="text-center">
                            <p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-5 col-end-7 text-center pt-6 hover:shadow-inner bg-slate-100">241,533.0</div>
                </div>
                <div class="grid grid-cols-6 ">
                    <div class="col-start-1 col-end-3 flex flex-col hover:shadow-inner bg-slate-100">
                        <div class="flex">
                            <img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/66.png" alt="Aston Villa" />
                            <p class="text-sm ml-3 mt-4">Aston Villa</p>
                        </div>
                        <div class="text-center">
                            <p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-3 col-end-5 flex flex-col hover:shadow-inner bg-slate-100">
                        <div class="flex">
                            <img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/45.png" alt="Everton" />
                            <p class="text-sm ml-3 mt-4">Everton</p>
                        </div>
                        <div class="text-center">
                            <p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-5 col-end-7 text-center pt-6 hover:shadow-inner bg-slate-100">122,245.0</div>
                </div>
                <div class="grid grid-cols-6 ">
                    <div class="col-start-1 col-end-3 flex flex-col hover:shadow-inner bg-slate-100">
                        <div class="flex"><img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/55.png" alt="Brentford" />
                            <p class="text-sm ml-3 mt-4">Brentford</p></div><div class="text-center"><p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-3 col-end-5 flex flex-col hover:shadow-inner bg-slate-100"><div class="flex">
                        <img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/33.png" alt="Manchester United" />
                        <p class="text-sm ml-3 mt-4">Manchester United</p></div><div class="text-center">
                            <p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-5 col-end-7 text-center pt-6 hover:shadow-inner bg-slate-100">178,988.0</div>
                </div>
                <div class="grid grid-cols-6 ">
                    <div class="col-start-1 col-end-3 flex flex-col hover:shadow-inner bg-slate-100"><div class="flex">
                        <img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/51.png" alt="Brighton" />
                        <p class="text-sm ml-3 mt-4">Brighton</p></div><div class="text-center"><p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-3 col-end-5 flex flex-col hover:shadow-inner bg-slate-100"><div class="flex">
                        <img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/34.png" alt="Newcastle" />
                        <p class="text-sm ml-3 mt-4">Newcastle</p></div><div class="text-center">
                            <p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-5 col-end-7 text-center pt-6 hover:shadow-inner bg-slate-100">98,359.0</div>
                </div>
                <div class="grid grid-cols-6 " >
                    <div class="col-start-1 col-end-3 flex flex-col hover:shadow-inner bg-slate-100">
                        <div class="flex"><img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/49.png" alt="Chelsea" />
                            <p class="text-sm ml-3 mt-4">Chelsea</p>
                        </div>
                        <div class="text-center"><p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-3 col-end-5 flex flex-col hover:shadow-inner bg-slate-100"><div class="flex">
                        <img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/47.png" alt="Tottenham" />
                        <p class="text-sm ml-3 mt-4">Tottenham</p>
                    </div>
                        <div class="text-center">
                            <p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-5 col-end-7 text-center pt-6 hover:shadow-inner bg-slate-100">235,544.0</div>
                </div>
                <div class="grid grid-cols-6 ">
                    <div class="col-start-1 col-end-3 flex flex-col hover:shadow-inner bg-slate-100">
                        <div class="flex">
                            <img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/40.png" alt="Liverpool" />
                            <p class="text-sm ml-3 mt-4">Liverpool</p>
                        </div>
                        <div class="text-center">
                            <p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-3 col-end-5 flex flex-col hover:shadow-inner bg-slate-100">
                        <div class="flex"><img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/52.png" alt="Crystal Palace" />
                            <p class="text-sm ml-3 mt-4">Crystal Palace</p>
                        </div>
                        <div class="text-center">
                            <p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-5 col-end-7 text-center pt-6 hover:shadow-inner bg-slate-100">242,422.0</div>
                </div>
                <div class="grid grid-cols-6 " >
                    <div class="col-start-1 col-end-3 flex flex-col hover:shadow-inner bg-slate-100">
                        <div class="flex">
                            <img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/50.png" alt="Manchester City" />
                            <p class="text-sm ml-3 mt-4">Manchester City</p>
                        </div>
                        <div class="text-center">
                            <p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-3 col-end-5 flex flex-col hover:shadow-inner bg-slate-100">
                        <div class="flex">
                            <img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/35.png" alt="Bournemouth" />
                            <p class="text-sm ml-3 mt-4">Bournemouth</p>
                        </div>
                        <div class="text-center">
                            <p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-5 col-end-7 text-center pt-6 hover:shadow-inner bg-slate-100">93,993.0</div>
                </div>
                <div class="grid grid-cols-6 ">

                    <div class="col-start-1 col-end-3 flex flex-col hover:shadow-inner bg-slate-100"><div class="flex">
                        <img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/65.png" alt="Nottingham Forest" />
                        <p class="text-sm ml-3 mt-4">Nottingham Forest</p></div><div class="text-center"><p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-3 col-end-5 flex flex-col hover:shadow-inner bg-slate-100"><div class="flex">
                        <img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/48.png" alt="West Ham" />
                        <p class="text-sm ml-3 mt-4">West Ham</p>
                    </div>
                        <div class="text-center">
                            <p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-5 col-end-7 text-center pt-6 hover:shadow-inner bg-slate-100">193,322.0</div>
                </div>
                <div class="grid grid-cols-6 ">
                    <div class="col-start-1 col-end-3 flex flex-col hover:shadow-inner bg-slate-100">
                        <div class="flex">
                            <img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/41.png" alt="Southampton" />
                            <p class="text-sm ml-3 mt-4">Southampton</p>
                        </div>
                        <div class="text-center">
                            <p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-3 col-end-5 flex flex-col hover:shadow-inner bg-slate-100">
                        <div class="flex">
                            <img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/63.png" alt="Leeds" />
                            <p class="text-sm ml-3 mt-4">Leeds</p>
                        </div>
                        <div class="text-center">
                            <p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-5 col-end-7 text-center pt-6 hover:shadow-inner bg-slate-100">355,606.0</div>
                </div>
                <div class="grid grid-cols-6 ">
                    <div class="col-start-1 col-end-3 flex flex-col hover:shadow-inner bg-slate-100">
                        <div class="flex">
                            <img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/39.png" alt="Wolves" />
                            <p class="text-sm ml-3 mt-4">Wolves</p>
                        </div>
                        <div class="text-center">
                            <p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-3 col-end-5 flex flex-col hover:shadow-inner bg-slate-100">
                        <div class="flex">
                            <img class="w-8 h-8 ml-4 mt-3" src="https://media.api-sports.io/football/teams/36.png" alt="Fulham" />
                            <p class="text-sm ml-3 mt-4">Fulham</p>
                        </div>
                        <div class="text-center">
                            <p class="text-xs font-thin">1.960</p>
                        </div>
                    </div>
                    <div class="col-start-5 col-end-7 text-center pt-6 hover:shadow-inner bg-slate-100">134,532.0</div>
                </div>
            </div>
            <div class="pb-4 bg-slate-100">
            </div>
        </div>)
}