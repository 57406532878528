import React from "react"
import ShowPurchaseRound from "./ShowPurchaseRound"
import ShowActiveRounds from "./ShowActiveRounds"

const buttonLink = {
    readMore: { href: "https://docs.blackboxfinancetech.com/blackbox-guide/participate-blackbox" }
}

export default function MainContent2() {
    return (
        <div className="relative bg-gradient-to-tl from-zinc-700 via-zinc-200 to-zinc-50">
            <section className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-12 lg:py-20">
                <h2 className="text-4xl font-semibold mb-8">How to participate</h2>
                <div className="h-auto md:grid md:gap-20 md:grid-cols-2 ">
                    <div className="border-zinc-400 border-2 rounded-lg h-96 mb-6 md:mb-0 md:h-auto overflow-auto md:max-h-screen"><ShowActiveRounds /></div>
                    <div className="flex flex-col content-start space-y-2 p-12 border-zinc-400 rounded-2xl bg-zinc-100 ">
                        <h3 className="text-xl font-semibold">Choose some Blackboxes.</h3>
                        <p className="text-base">Each Blackbox is different. Choosing with deep imagination is crucial to anticipate the box’s rewards. </p>
                        <h3 className="text-xl font-semibold">Wait the round ends.</h3>
                        <p className="text-base">Blackbox will be opened once the round ends.</p>
                        <h3 className="text-xl font-semibold">Open Blackbox, enjoy rewards.</h3>
                        <p className="text-base">Rewards can be either USDC more than you bought or BLACK token. </p>
                        <br></br>
                        <a href={buttonLink.readMore.href} key="Read more" className="text-center border rounded-md w-32 p-2 bg-sky-700 text-white hover:bg-sky-600 hover:font-medium">Read more</a>
                    </div>
                </div>
            </section>
        </div>
    )
}